.transaction_table_root{
    height: 100%;
    &_wrap{
        background-color: var(--pro-secondary);
        
    }
    .table_root{
        height: calc(100vh - 222px);
        overflow-y: auto;
        .table{
            width: 100%;
            td{
                padding: 11px 0;
            }
            tr:last-child td{
                border-top: 1px solid var(--pro-outline-border);
                &:last-child{
                    > p{
                        font-size: 18px;
                        font-weight: 700 !important;
                    }
                }
            }
        }
        .gray_text{
            color: #4C4C4C;
        }
    }
}
.btn_container{
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: var(--pro-secondary);
    button{
        --pro-disable-bg: #cdcdcd;
        --pro-disable-text : #fdfdfd;
      }
}