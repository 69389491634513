
.root{
    max-width: 360px;
    .profile_top{
        border-bottom: 1px solid var(--pro-outline-border);
    }
    table{
        td{
            padding: 5px 0;
            width: 50%;
            .table_content{
                height: 10px;
                width: 75%;
            }
            & + td{
                padding-left: 5px;
                .table_content{
                    width: 90%;
                }
            }
        }
    }
}