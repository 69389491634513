.header {
  background-color: var(--pro-light);
  box-shadow: 0 0 4px 1px rgb(9 30 66 / 13%), 0 0 4px 0 rgb(9 30 66 / 8%);
  padding: 10px 0;
  width: 100vw;
  right: initial;
  padding-right: 0 !important;
  :global(.nav-link) {
    --pro-nav-link-padding-y: 0;
  }
  :global(.nav-link .pro-btn-link::before) {
    bottom: -17px;
  }
  :global(.dropdown) button:global(.dropdown-toggle) {
    border-radius: 50%;
    outline-color: var(--pro-border-color-translucent);
    display: flex;
  }
  a figure {
    min-width: 86px;
    max-width: 86px;
    padding: 7px 0;
    img {
      max-width: 100%;
    }
  }
}
:global(.sub-icon) {
  width: 24px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  opacity: 1;
  transition: 0.3s all ease;
}
:global(.expanded .sub-icon) {
  opacity: 0;
}
.MenuDrawer_container {
  :global(.nav-link .material-symbols-outlined) {
    max-width: 22px;
  }
}

.profile_container {
  border-right: 1px solid var(--pro-outline-border);
}
