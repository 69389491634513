.reactSelectContainer {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}



.table_wraper > div {
  // display: flex;
  // justify-content: center;
  padding: 1rem 0;
}
