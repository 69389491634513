.root{
    width: 100%;
    .root_table{
        border: 1px solid var(--pro-outline-border);
        border-radius: 5px;
        border-collapse: separate;
        border-spacing: 0;
        tr {
            td{
                padding: 10px;
                &:last-child{
                    text-align: end;
                }
            }
            &:nth-child(odd){
                background-color: var(--pro-secondary);
            }
            
        }
        .td_left{
            color: var(--pro-input-text);
        }
    }
}