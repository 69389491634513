.reactSelectContainer{
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.modal_root{
  --pro-modal-width: 800px;
  .modal_sticky_body{
    max-height: 400px;
    overflow-y: auto;
    line-height: 1.65;
  }
}