.table_root {
  width: 100%;
  overflow-x: auto;
}

.table_wrap {
  table th {
    white-space: normal;
  }
}
