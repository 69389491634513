.root {
  :global(.dropdown) {
    width: auto;
    :global(.dropdown-menu) {
      width: 350px;
    }
    :global(.dropdown-item:first-child) {
      &:hover {
        background-color: transparent !important;
      }
    }
    button {
      background: none;
      border: none;
      &::after {
        display: none;
      }
    }
  }
}

.dropdown {
  top: 12px !important;
  --pro-dropdown-zindex: 1000;
  --pro-dropdown-min-width: 10rem;
  --pro-dropdown-padding-x: 0;
  --pro-dropdown-padding-y: 0.5rem;
  --pro-dropdown-spacer: 0.125rem;
  --pro-dropdown-font-size: 1rem;
  --pro-dropdown-color: var(--pro-dark);
  --pro-dropdown-bg: var(--pro-light);
  --pro-dropdown-border-color: var(--pro-outline-border);
  --pro-dropdown-border-radius: 3px;
  --pro-dropdown-border-width: 1px;
  --pro-dropdown-inner-border-radius: 2px;
  --pro-dropdown-divider-bg: var(--pro-outline-border);
  --pro-dropdown-divider-margin-y: 0.5rem;
  --pro-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --pro-dropdown-link-color: var(--pro-dark);
  --pro-dropdown-link-hover-color: var(--pro-light);
  --pro-dropdown-link-hover-bg: var(--pro-primary);
  --pro-dropdown-link-active-color: #fff;
  --pro-dropdown-link-active-bg: var(--pro-primary);
  --pro-dropdown-link-disabled-color: #adb5bd;
  --pro-dropdown-item-padding-x: 1rem;
  --pro-dropdown-item-padding-y: 0.25rem;
  --pro-dropdown-header-color: #6c757d;
  --pro-dropdown-header-padding-x: 1rem;
  --pro-dropdown-header-padding-y: 0.5rem;
  &::after {
    content: "";
    position: absolute;
    top: -9px;
    right: 9px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid var(--pro-outline-border);
  }
  a {
    transition: 0.18s all ease-in-out;
  }
}

.user_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--pro-dropdown-border-color);
  padding-bottom: 15px;
  padding-top: 8px;
  .image_wrapper {
    width: 60px;
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
      padding: auto;
      left: 0;
      top: 0;
      border-radius: 50%;
      overflow: hidden;
    }
    .user_img {
      font-size: 30px !important;
    }
  }
  .content_wrapper {
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 20px;
    align-items: center;

    .staff_name {
      font-size: 16px;
      line-height: 1.2;
      color: var(--pro-dark);
      text-decoration: none;
      font-weight: 500;
      width: 100%;
      margin-bottom: 5px;
    }
    .staff_email {
      font-size: 14px;
      line-height: 1.2;
      color: var(--pro-dark);
      text-decoration: none;
      font-weight: 400;
      width: 100%;
      opacity: 0.5;
      margin-bottom: 5px;
    }
    .staff_number {
      font-size: 14px;
      line-height: 1.2;
      color: var(--pro-dark);
      text-decoration: none;
      font-weight: 400;
      width: 100%;
      opacity: 0.5;
      margin-bottom: 0;
    }
  }
}

.logout {
  margin-left: 15px;
  width: 150px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 12px;
  // border: 1px solid var(--pro-primary) !important;
}
