.transaction_table_root {
  height: 100%;
  &_wrap {
    background-color: var(--pro-secondary);
  }
  .table_root {
    height: calc(100vh - 222px);
    overflow-y: auto;
    .table {
      width: 100%;
      td {
        padding: 11px 0;
      }
      tr:last-child td {
        border-top: 1px solid var(--pro-outline-border);
        &:last-child {
          > p {
            font-size: 18px;
            font-weight: 700 !important;
          }
        }
      }
    }
  }
}
.gray_text {
  color: #4c4c4c;
}
.btn_container {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: var(--pro-secondary);
  button{
    --pro-disable-bg: #cdcdcd;
    --pro-disable-text : #fdfdfd;
  }
}
.purchase_form_wrap .remove_btn {
  --pro-button-color: var(--pro-danger);
  --pro-button-hover-color: var(--pro-danger);
}

.footer_btn_wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid var(--pro-outline-border);
  background-color: var(--pro-light);
  z-index: 9;
}
.confirmation_preview_wrapper {
  padding-bottom: 80px;
}
.customer_head {
  border-bottom: 1px solid var(--pro-outline-border);
}

.cutsomer_title {
  color: #4c4c4c;
  span {
    color: var(--pro-dark);
  }
}

.customer_table {
  border-bottom: 1px solid var(--pro-outline-border);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  table {
    width: 100%;
    td {
      padding: 5px 0;
      &:first-child {
        width: 20%;
      }
      & + td {
        padding-left: 10px;
      }
    }
  }
}
