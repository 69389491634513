.root{
    --pro-gutter-x: 10px;
    --pro-gutter-y : 16px;
    >*{
        width: 14.285%;
    }
}
.item{
    border-radius: 6px;
    border: 1px solid var(--pro-outline-border);
    :global{
        .letter{
            height: 6px;
            width: 40%;
            & + .letter{
                width: 30%;
            }
        }
    }
}