@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/bootstrap.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/common.scss";
@import "swiper/swiper.min.css";
@import "./config";
:root,
[data-theme*="light"] {
  @include light-theme;
  --pro-range-bg: #{$pro-range-bg-light};
  --pro-image-gray-overlay: #{$pro-image-gray-overlay-light};
  --pro-invoice-label: #{$pro-invoice-label-light};
  --pro-input-focus-shadow: #{$pro-input-focus-shadow-light};
  --pro-input-option-hover-bg: #{$pro-input-option-hover-bg-light};
  --pro-input-option-active-bg: #{$pro-input-option-active-bg-light};
  --pro-staff-card-bg: #{$pro-staff-card-bg-light};
  --pro-nav-pill-link-active-color: #{$pro-nav-pill-link-active-color-light};
  --pro-toggle-bg: #{$pro-toggle-bg-light};
  --pro-warning: #{$pro-warning-light};
}
[data-theme*="dark"] {
  @include dark-theme;
  --pro-range-bg: #{$pro-range-bg-dark};
  --pro-image-gray-overlay: #{$pro-image-gray-overlay-dark};
  --pro-invoice-label: #{$pro-invoice-label-dark};
  --pro-input-focus-shadow: #{$pro-input-focus-shadow-dark};
  --pro-input-option-hover-bg: #{$pro-input-option-hover-bg-dark};
  --pro-input-option-active-bg: #{$pro-input-option-active-bg-dark};
  --pro-staff-card-bg: #{$pro-staff-card-bg-dark};
  --pro-nav-pill-link-active-color: #{$pro-nav-pill-link-active-color-dark};
  --pro-toggle-bg: #{$pro-toggle-bg-dark};
  --pro-warning: #{$pro-warning-dark};
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fafafa;
  }
  &::-webkit-scrollbar-thumb {
    // background-color: var(--pro-input-focus-shadow);
    background-color: #e2e2e2;
  }
  scrollbar-width: thin;
}

body {
  font-size: $font-size-normal;
  line-height: $primary-line-height;
  --toastify-toast-min-height: 56px;
  --toastify-z-index: 999;
}
.pro-toast-container {
  top: 60px !important;
  .pro-toast-progress {
    height: 2px;
  }
}
.wrapper {
  width: max-content;
  height: calc(100vh - 65px);
  position: sticky;
  top: 65px;
  > div {
    height: 100%;
  }
}
.wrapper-table {
  flex: 1;
  width: calc(100% - 260px);
  // overflow: hidden;
  .pro-pagination {
    display: flex;
    justify-content: center;
  }
}
.wrapper-tables {
  width: 100%;
  overflow: hidden;
}

.pt-custom {
  padding-top: size(65);
}

.react-transform-component,
.react-transform-wrapper {
  width: 100% !important;
}

.scoll-top {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--pro-primary);
  background-color: var(--pro-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  z-index: 999;
  transition: 0.3s all ease-in-out;
  span {
    font-size: 25px !important;
    color: var(--pro-primary);
    transition: 0.3s all ease-in-out;
  }

  &:hover {
    background-color: var(--pro-primary);
    span {
      color: var(--pro-light) !important;
    }
  }
}

.pro-btn.pro-btn-outline-primary {
  --pro-button-color: var(--pro-primary);
  --pro-button-hover-color: var(--pro-light);
  border: 1px solid var(--pro-primary);
  --pro-button-hover-bg: var(--pro-primary);
  --pro-button-padding-y: #{size(8)};
  &:active {
    background-color: var(--pro-primary);
  }
}
.app-loading {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  &::after {
    width: 30px;
    height: 30px;
    border: 2px solid var(--pro-primary);
    border-radius: 50%;
    overflow: hidden;
    border-bottom-color: transparent;
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: rotation 0.6s infinite linear;
  }
  &.fill {
    position: fixed;
  }
}
@keyframes rotation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.pro-pnt {
  color: var(--pro-primary);
  font-weight: 600; // no variables for fw-semibold
  cursor: pointer;
}

.pro-no-point {
  pointer-events: none;
}
.pointer{
  cursor: pointer;
}

.shimmer {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  -webkit-animation-timing-function: linear;
  &.letter {
    width: 100%;
    height: 15px;
    &.sub {
      width: 90%;
    }
    &.para {
      height: 6px;
    }
  }
  &.img {
    padding-bottom: 100%;
    width: 100%;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.blink {
  background-color: #f9f9f9;
  display: inline-block;
  position: relative;
  -webkit-animation-name: blink;
  -webkit-animation-duration: var(--blink-duration);
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: var(--blink-delay);
  &.img {
    padding-bottom: 100%;
    width: 100%;
  }
}
@keyframes blink {
  0% {
    background-color: #f9f9f9;
  }
  10% {
    background-color: #f6f6f6;
  }
  20% {
    background-color: #f3f3f3;
  }
  30% {
    background-color: #f1f1f1;
  }
  40% {
    background-color: #e9e9e9;
  }
  50% {
    background-color: #e1e1e1;
  }
  60% {
    background-color: #e9e9e9;
  }
  70% {
    background-color: #f1f1f1;
  }
  80% {
    background-color: #f3f3f3;
  }
  90% {
    background-color: #f6f6f6;
  }
  100% {
    background-color: #f9f9f9;
  }
}
.input-country-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  .input-main-wrap {
    position: relative;
    width: 100%;
    .code-wrap {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.34375rem 0.4375rem;
      font-size: 0.85rem;
      max-width: 84px;
      cursor: pointer;
      user-select: none;
      &::after {
        position: absolute;
        content: "";
        width: 1px;
        top: 5px;
        bottom: 5px;
        right: 0;
        background-color: var(--pro-border-color);
      }
      .pro-input__control {
        border: 0;
        box-shadow: none !important;
      }
      .pro-input__value-container {
        padding: 0;
      }
      .pro-input__indicator-separator {
        display: none;
      }
      .pro-input__placeholder {
        font-size: 14px;
        color: var(--pro-dark);
      }
      .pro-input__indicator {
        padding: 4px;
      }
      .pro-input__menu-list {
        max-height: 250px;
      }
      .pro-input__menu {
        width: max-content;
        max-width: 300px;
      }
    }
    .pro-input {
      padding-left: 6rem;
    }
  }
  .error-text {
    font-size: 12px;
    line-height: $secondary-line-height;
    margin-top: 10px;
    color: var(--pro-danger);
  }
}

.verify-btn-wrap {
  position: relative;
  > .pro-btn {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0%, -50%);
  }
}
.error-text {
  font-size: 12px;
  line-height: $secondary-line-height;
  margin-top: 10px;
  color: var(--pro-danger);
}
.offcanvas-footer-sticky-btns {
  position: sticky;
  bottom: -20px;
  padding: 16px 0;
  margin-bottom: -20px;
  background-color: var(--pro-light);
  z-index: 8;
}

.search-filter-select {
  min-width: 200px;
}

.pro-input__option {
  &:not(.pro-input__option--is-selected).pro-input__option--is-focused {
    background-color: var(--pro-input-option-hover-bg);
  }
  &:active {
    background-color: var(--pro-input-option-active-bg);
  }
  &.pro-input__option--is-selected {
    background-color: var(--pro-primary) !important;
    color: var(--pro-light) !important;
  }
}

.hide-icon-input {
  padding-right: 40px;
}

.p-re {
  position: relative;
  .input-wrap {
    position: relative;
  }
  .hide-icon {
    right: 12px;
  }
}

.hide-icon {
  position: absolute;
  right: 0;
  top: 24px;
  border: none;
  background-color: transparent;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .pro-btn-link .material-symbols-outlined {
  font-size: 1.5rem !important;
}

.dropdown .dropdown-item {
  transition: all 0.3s ease-in;
}

.pro-table th.checkbox-cell,
.pro-table td.checkbox-cell {
  min-width: 44px;
}

.nav-pills .nav-item .nav-link {
  color: var(--pro-nav-pill-link-active-color);
}

.mobile-number-wrapper {
  display: flex;
  align-items: center;
}

.single-field {
  display: flex;
  margin-right: 10px;
}

.pro-input.lg {
  width: 100%; /* Adjust the width as needed */
}
a.link-primary {
  color: var(--pro-primary);
}

.detail-page .pro-pagination-wrapper {
  width: calc(100% - 361px);
}

.dropdown-filter .pro-input .pro-input__single-value {
  padding: 0;
}

//calanderstyls
.pro-input-calendar.react-calendar {
  width: 100%;
  max-width: size(300);
  min-width: size(250);
  border-color: var(--pro-outline-border);
  border-radius: 3px;
  background-color: var(--pro-light);
  box-shadow: 0px 0px 0px rgba($color: #000000, $alpha: 0.2);
  position: absolute;
  top: calc(100% + 3px);
  z-index: 9;
  .react-calendar__viewContainer {
    .react-calendar__year-view {
      .react-calendar__tile {
        padding: 16px 5px;
        transition: background-color 0.25s ease-in;
        &:not(.react-calendar__tile--hasActive) {
          &:enabled:hover,
          &:enabled:focus {
            background-color: var(--pro-nav-btn-link-hover);
          }
          &.react-calendar__tile--now {
            background-color: #d5ffdc;
            color: var(--pro-dark);
          }
        }

        &.react-calendar__tile--hasActive {
          background-color: var(--pro-primary);
          color: var(--pro-light);
        }
      }
    }
  }
  .react-calendar__navigation {
    .react-calendar__navigation__label {
      font-weight: 600;
      font-size: 16px;
    }
    button {
      transition: background-color 0.25s ease-in;
    }
    button:enabled:hover,
    button:enabled:hover {
      background-color: var(--pro-nav-btn-link-hover);
    }
    .react-calendar__navigation__arrow {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.pro-toggle{
  display: flex;
  align-items: center;
  .pro-toggle-box{
    position: relative;
    width: 28px;
    height: 16px;
    input[type="checkbox"] {
      width: 100%;
      height: 100%;
      appearance: none;
      background: var(--pro-toggle-bg);
      border-radius: 100px;
      cursor: pointer;
      transition: 0.4s;
    }
    
    input:checked[type="checkbox"] {
    background: var(--pro-primary);
  }
  
  input[type="checkbox"] ~ span {
      position: absolute;
      content: "";
      width: 13px;
      height: 13px;
      top: 50%;
      left: 1px;
      background: var(--pro-light);
      border-radius: 50%;
      transition: 0.3s;
      pointer-events: none;
      transform: translateY(-50%);
  }
  
  input:checked[type="checkbox"] ~ span {
      left: 49%;
  }
  & + label{
    margin-left: 12px;
    color: var(--pro-input-text);
    font-size: 13px;
    margin-bottom: 0;
  }
  }
}

.pro-link-danger{
  color: var(--pro-danger);
}
.pro-link-warning{
  color: var(--pro-warning);
}