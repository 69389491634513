.reactSelectContainer{
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.table_root{
  width: 100%;
  overflow-x: auto;
}

.table_wraper > div {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
