.reactSelectContainer{
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.conform_pill{
  border-radius: 20px;
  padding: 5px 10px;
  color: var(--pro-light);
  font-size: 12px;
  &.green{
      background-color: var(--pro-primary);
  }
  &.red{
      background-color: var(--pro-danger);
  }    
  &.blue{
    background-color: rgba(var(--pro-link-color-rgb), 1);
  }
  &.orange{
    background-color: var(--pro-warning);
  }
}