.root{
    width: 360px;
    height: 100%;
    position: relative;
    .table_wrap{
        overflow-y: auto;
    height: 100%;
    padding-inline: 1.5rem;
    position: relative;
    z-index: 9;

        .table{
            width: 100%;
            td {
                width: 40%;
                padding: 5px 0;
                & + td{
                    padding-left: 5px;
                    width: 60%;
                }
                a{
                    color: var(--pro-dark);
                    text-decoration: none;
                    font-weight: 500;
                    word-break: break-all;
                }
            }
        }
    }
    .profile_top{
        padding-inline: 1.5rem;
        position: sticky;
        > div{
            border-bottom: 1px solid var(--pro-border-color);
        }
    }
}
.repeat_details{
    ~ .repeat_details{
        margin-top: 0.8rem;
        border-top: 1px solid var(--pro-border-color);
        padding-top: 0.8rem;
    }
}